// -----------------------------------------------------------------------
// Import resources
// -----------------------------------------------------------------------

import request from "superagent";
import { getAuthToken } from "../utils";

const BASE = "https://staging.playo.io";

const BASE_URL = "https://staging.playo.io/controller/ppc";
const BASE_URL_PUBLIC = "https://staging.playo.io/controller-public/ppc";

// const BASE = "https://api.playo.io";

// const BASE_URL =
  // process.env.REACT_APP_BASE_URL || "https://api.playo.io/controller/ppc";
// const BASE_URL_PUBLIC =
  // process.env.REACT_APP_BASE_URL_PUBLIC ||
  // "https://api.playo.io/controller-public/ppc";

// -----------------------------------------------------------------------
// Api Class
// -----------------------------------------------------------------------
export default class Api {
  // -----------------------------------------------------------------------
  // getCountryDetailsData()
  // -----------------------------------------------------------------------
  // static getCountryDetailsData() {
  // 	return new Promise((resolve, reject) => {
  // 		request
  // 			.get(
  // 				`https://restcountries.com/v2/all?fields=name,alpha3Code,callingCodes,flags`
  // 			)
  // 			.accept(`application/json`)

  // 			.end((error, res) => {
  // 				error ? reject(res) : resolve(res);
  // 			});
  // 	});
  // }
  static getCountryDetailsData() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE}/utils/thirdparty/countries`)
        .set("Authorization", getAuthToken())
        .accept(`application/json`)

        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getDefaultCountryDetailsData()
  // -----------------------------------------------------------------------
  static getDefaultCountryDetailsData(callingCode) {
    return new Promise((resolve, reject) => {
      request
        .get(
          `https://restcountries.eu/rest/v2/callingcode/${callingCode}?fields=name;alpha3Code;callingCodes;flag`
        )
        .accept(`application/json`)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getActivitiesData()
  // -----------------------------------------------------------------------
  static getActivitiesData() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/activities`)
        .set("Authorization", getAuthToken())
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getTransactionCodesAndTransactionTypesData()
  // -----------------------------------------------------------------------
  static getTransactionCodesAndTransactionTypesData() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/transaction/codes`)
        .set("Authorization", getAuthToken())
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getSlotsData()
  // -----------------------------------------------------------------------
  static getSlotsData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/availability`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getCartData()
  // -----------------------------------------------------------------------
  static getCartData() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/carting/details`)
        .set("Authorization", getAuthToken())
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // addSlotToCartData()
  // -----------------------------------------------------------------------
  static addSlotToCartData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/carting/slot/add`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // clearCartData()
  // -----------------------------------------------------------------------
  static clearCartData() {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/carting/clear`)
        .set("Authorization", getAuthToken())
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // removeFromCartData()
  // -----------------------------------------------------------------------
  static removeFromCartData(cartedSlotId) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/carting/slot/remove`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .query(`cartedSlotId=${cartedSlotId}`)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // // -----------------------------------------------------------------------
  // // getCountryCodesData()
  // // -----------------------------------------------------------------------
  // static getCountryCodesData() {
  //   return new Promise((resolve, reject) => {
  //     request
  //       .get(`${BASE_URL}/country/codes`)
  //       .set("Authorization", getAuthToken())
  //       .accept(`application/json`)
  //       .withCredentials()
  //       .end((error, res) => {
  //         error ? reject(error) : resolve(res);
  //       });
  //   });
  // }

  // -----------------------------------------------------------------------
  // getCustomerDetailsData()
  // -----------------------------------------------------------------------
  static getCustomerDetailsData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/customer/details`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // redeemCreditsData()
  // -----------------------------------------------------------------------
  static redeemCreditsData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/club/credits/redeem`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // resetCreditsData()
  // -----------------------------------------------------------------------
  static resetCreditsData() {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/club/credits/reset`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // applyDiscountData()
  // -----------------------------------------------------------------------
  static applyDiscountData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/club/discount/apply`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // resetDiscountData()
  // -----------------------------------------------------------------------
  static resetDiscountData() {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/club/discount/reset`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getCourtListData()
  // -----------------------------------------------------------------------
  static getCourtListData(activityId) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/court/details`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .query(`activityId=${activityId}`)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // verifySlotsAvailabilityData()
  // -----------------------------------------------------------------------
  static verifySlotsAvailabilityData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/slots/availability/verify`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // patternSlotsBookingData()
  // -----------------------------------------------------------------------
  static patternSlotsBookingData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/pattern/slots`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // blockingData()
  // -----------------------------------------------------------------------
  static blockingData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/blocking`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // unblockingData()
  // -----------------------------------------------------------------------
  static unblockingData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/unblocking`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // bookingData()
  // -----------------------------------------------------------------------
  static bookingData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // patternBookingData()
  // -----------------------------------------------------------------------
  static patternBookingData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/pattern`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getBookingDetailsData()
  // -----------------------------------------------------------------------
  static getBookingDetailsData(bookingId) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/booking/details`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .query({ bookingId })
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getRegularBookingSearchResultData()
  // -----------------------------------------------------------------------
  static getRegularBookingSearchResultData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/booking/search`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .query(reqParams)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getBulkBookingSearchResultData()
  // -----------------------------------------------------------------------
  static getBulkBookingSearchResultData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/booking/pattern/search`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .query(reqParams)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getBlockingSearchResultData()
  // -----------------------------------------------------------------------
  static getBlockingSearchResultData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/blocking/search`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .query(reqParams)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateBookingRemarksData()
  // -----------------------------------------------------------------------
  static updateBookingRemarksData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/remarks/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateBookingPaymentData()
  // -----------------------------------------------------------------------
  static updateBookingPaymentData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/payment/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateBookingPaymentData()
  // -----------------------------------------------------------------------
  static updateExtraPaymentData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/extras/payment/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getTransactionsDetailsData()
  // -----------------------------------------------------------------------
  static getTransactionsDetailsData(bookingId) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/transactions/details`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .query({ bookingId })
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // cancelBookingData()
  // -----------------------------------------------------------------------
  static cancelBookingData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/cancellation`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getMemberDetailsData()
  // -----------------------------------------------------------------------
  static getMemberDetailsData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/member/search`)
        .set("Authorization", getAuthToken())
        .query(reqParams)
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getIndividualMemberDetailsData()
  // -----------------------------------------------------------------------
  static getIndividualMemberDetailsData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/member/search`)
        .set("Authorization", getAuthToken())
        .query(reqParams)
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // registerMemberData()
  // -----------------------------------------------------------------------
  static registerMemberData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/member/registration`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateMemberDetailsData()
  // -----------------------------------------------------------------------
  static updateMemberDetailsData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/member/details/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // createMemberPinData()
  // -----------------------------------------------------------------------
  static createMemberPinData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/member/pin/create`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateMemberPinData()
  // -----------------------------------------------------------------------
  static updateMemberPinData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/member/pin/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // rechargeMemberCreditsData()
  // -----------------------------------------------------------------------
  static rechargeMemberCreditsData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/member/credits/recharge`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateMemberCreditsValidityData()
  // -----------------------------------------------------------------------
  static updateMemberCreditsValidityData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/member/credits/validity/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // verifyBookingRescheduleAvailabilityData()
  // -----------------------------------------------------------------------
  static verifyBookingRescheduleAvailabilityData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/reschedule/verify`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getBookingReschedulePricingData()
  // -----------------------------------------------------------------------
  static getBookingReschedulePricingData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/reschedule/pricing`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // confirmBookingRescheduleData()
  // -----------------------------------------------------------------------
  static confirmBookingRescheduleData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/reschedule`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getExtrasData()
  // -----------------------------------------------------------------------
  static getExtrasData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/extras`)
        .set("Authorization", getAuthToken())
        .query(reqParams)
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getClubExtrasListData()
  // -----------------------------------------------------------------------
  static getClubExtrasListData(activityId) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/club/extras`)
        .set("Authorization", getAuthToken())
        .query(activityId)
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // addExtraData()
  // -----------------------------------------------------------------------
  static addExtraData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/carting/extra/add`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  static addCreditWithExtra(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/carting/extra/credits/redeem`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }
  // -----------------------------------------------------------------------
  // removeExtraData()
  // -----------------------------------------------------------------------
  static removeExtraData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/carting/extra/remove`)
        .set("Authorization", getAuthToken())
        .query(reqParams)
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getMembershipPackageDetailsData()
  // -----------------------------------------------------------------------
  static getMembershipPackageDetailsData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/membership/package/details`)
        .set("Authorization", getAuthToken())
        .query(reqParams)
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // createMembershipPackageData()
  // -----------------------------------------------------------------------
  static createMembershipPackageData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/membership/package/create`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // subscribeMembershipPackageData()
  // -----------------------------------------------------------------------
  static subscribeMembershipPackageData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/membership/subscription`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getMembershipSubscriptionDetailsData()
  // -----------------------------------------------------------------------
  static getMembershipSubscriptionDetailsData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/membership/subscription/details`)
        .set("Authorization", getAuthToken())
        .query(reqParams)
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // generateCouponData()
  // -----------------------------------------------------------------------
  static generateCouponData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/coupon/generate`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // generateCouponData()
  // -----------------------------------------------------------------------
  static editCouponData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/coupon/details/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // membershipSubscriptionPaymentUpdateData()
  // -----------------------------------------------------------------------
  static membershipSubscriptionPaymentUpdateData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/membership/subscription/payment/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getMembersSubscribedPackagesData()
  // -----------------------------------------------------------------------
  static getMembersSubscribedPackagesData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/member/search/subscriptions`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateMembershipPackageDetailsData()
  // -----------------------------------------------------------------------
  static updateMembershipPackageDetailsData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/membership/package/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getPriceClassData()
  // -----------------------------------------------------------------------
  static getPriceClassData() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/pricing/class/details`)
        .set("Authorization", getAuthToken())
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getPricingConfigDetailsData()
  // -----------------------------------------------------------------------
  static getPricingConfigDetailsData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/pricing/config/details`)
        .set("Authorization", getAuthToken())
        .query(reqParams)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // savePricingDetailsData()
  // -----------------------------------------------------------------------
  static savePricingDetailsData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/pricing/config`)
        .set(`Content-Type`, `application/json`)
        .set("Authorization", getAuthToken())
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getClubHandlersData()
  // -----------------------------------------------------------------------
  static getClubHandlersData() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/club/users`)
        .set("Authorization", getAuthToken())
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getReportsData()
  // -----------------------------------------------------------------------
  static getReportsData(reqParams, type) {
    console.log({ reqParams });
    let typeUrl = "";
    typeUrl =
      type === "transaction"
        ? "/reports/transactions/multiple-clubs"
        : type === "booking"
        ? "/reports/bookings/multiple-clubs"
        : type === "balance"
        ? "/reports/balance/multiple-clubs"
        : type === "cancellation"
        ? "/reports/cancellations/multiple-clubs"
        : type === "credits"
        ? "/reports/credits/multiple-clubs"
        : type === "recharge"
        ? "/reports/recharge/multiple-clubs"
        : type === "extras"
        ? "/reports/extras/multiple-clubs"
        : null;
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}${typeUrl}`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqParams)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // downloadReportData()
  // -----------------------------------------------------------------------
  static downloadReportData(reqParams, type) {
    console.log({ reqParams });
    let typeUrl = "";

    typeUrl =
      type === "transaction"
        ? "/reports/transactions/multiple-clubs/download"
        : type === "booking"
        ? "/reports/bookings/multiple-clubs/download"
        : type === "balance"
        ? "/reports/balance/multiple-clubs/download"
        : type === "cancellation"
        ? "/reports/cancellations/multiple-clubs/download"
        : type === "credits"
        ? "/reports/credits/multiple-clubs/download"
        : type === "recharge"
        ? "/reports/recharge/multiple-clubs/download"
        : type === "extras"
        ? "/reports/extras/multiple-clubs/download"
        : null;
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}${typeUrl}`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqParams)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // loginData()
  // -----------------------------------------------------------------------
  static loginData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL_PUBLIC}/login`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // logoutData()
  // -----------------------------------------------------------------------
  static logoutData() {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL_PUBLIC}/logout`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getHolidaysData()
  // -----------------------------------------------------------------------
  static getHolidaysData() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/pricing/holiday/details`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // configureHolidayData()
  // -----------------------------------------------------------------------
  static configureHolidayData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/pricing/holiday/config`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // removeHolidayData()
  // -----------------------------------------------------------------------
  static removeHolidayData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/pricing/holiday/remove`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // generateReceiptData()
  // -----------------------------------------------------------------------
  static generateReceiptData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/receipt`)
        .set("Authorization", getAuthToken())
        .query(reqParams)
        .accept(`application/json`)
        .responseType("blob")
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // createUserData()
  // -----------------------------------------------------------------------
  static createUserData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/user/create`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // changeUserPasswordData()
  // -----------------------------------------------------------------------
  static changeUserPasswordData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/password/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // generateBookingStatementData()
  // -----------------------------------------------------------------------
  static generateBookingStatementData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/booking/statement`)
        .set("Authorization", getAuthToken())
        .query(reqParams)
        .accept(`application/json`)
        .responseType("blob")
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getEscalationMatrixData()
  // -----------------------------------------------------------------------
  static getEscalationMatrixData() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/escalation-matrix`)
        .set("Authorization", getAuthToken())
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // switchAccount()
  // -----------------------------------------------------------------------
  static switchAccount(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/switch/account`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getUtilisationData()
  // -----------------------------------------------------------------------
  static getUtilisationData(reqParams) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/club/utilisation`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .query(reqParams)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getSlotLockedStatus()
  // -----------------------------------------------------------------------
  static getSlotLockedStatus(slotData, date) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/slot/locked/status`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .query({
          activityId: slotData["activityId"],
          date,
          slot: slotData["slotTime"],
          courtId: slotData["courtId"],
        })
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getCouponDetails()
  // -----------------------------------------------------------------------
  static getCouponDetails(couponData) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/coupon/details`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .query(couponData)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getCouponCodes()
  // -----------------------------------------------------------------------
  static getCouponCodes(couponData) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/coupon/codes`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .query(couponData)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateCouponCodes()
  // -----------------------------------------------------------------------
  static updateCouponCodes(couponData) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/coupon/codes/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .send(couponData)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // sendBookingPaymentLink()
  // -----------------------------------------------------------------------
  static sendBookingPaymentLink(bookingId) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/payment/link`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .send({ bookingId })
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // sendBookingPaymentLink()
  // -----------------------------------------------------------------------
  static getInTouchViaMobile(getInTouchData) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/get-in-touch`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .send(getInTouchData)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // sendBookingPaymentLink()
  // -----------------------------------------------------------------------
  static editBookingAmount(editBookingAmountData) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/amount/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .send(editBookingAmountData)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getUserPermissions()
  // -----------------------------------------------------------------------
  static getUserPermissions(userId) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/user/permissions`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .query(userId)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateuserPermissions()
  // -----------------------------------------------------------------------
  static updateUserPermissions(permissions) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/user/permissions/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .send(permissions)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // removeUser()
  // -----------------------------------------------------------------------
  static removeUser(data) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/user/remove`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .send(data)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getFeatureList()
  // -----------------------------------------------------------------------
  static getFeatureList() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/features?premiumAccess=0`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)

        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // sendBookingPaymentLink()
  // -----------------------------------------------------------------------
  static getActivityTypeOneDetails(data) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/slot/booking/details`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .send(data)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updatExtraData()
  // -----------------------------------------------------------------------
  static updateExtraData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/extra/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updatBulkExtraData()
  // -----------------------------------------------------------------------
  static updateBulkExtraData(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/pattern/extra/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getConfigExtraList()
  // -----------------------------------------------------------------------
  static getConfigExtraList() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/club/extras/list`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)

        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateConfigExtra()
  // -----------------------------------------------------------------------
  static updateConfigExtra(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/club/extras/update`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // addConfigExtra()
  // -----------------------------------------------------------------------
  static addConfigExtra(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/club/extras/configure`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // downloadtExtra()
  // -----------------------------------------------------------------------
  static downloadExtra() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/club/extras/list/download`)
        .set("Authorization", getAuthToken())
        .query()
        .accept(`application/json`)
        .responseType("blob")
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // enableReschedule()
  // -----------------------------------------------------------------------
  static enableReschedule(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/reschedule/enable`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // markNoShow ()
  // -----------------------------------------------------------------------
  static markNoShow(reqBody) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/noshow`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(reqBody)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // syncAvailability ()
  // -----------------------------------------------------------------------

  static syncAvailability() {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/availability/sanity`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }
  // -----------------------------------------------------------------------
  // getDashboardData()
  // -----------------------------------------------------------------------
  static getDashboardData(data) {
    let { activityId, period, fromDate, toDate } = data;
    console.log({ data });
    return new Promise((resolve, reject) => {
      request
        .get(
          `${BASE_URL}/dashboard?activityId=${activityId}&period=${period}&fromDate=${fromDate}&toDate=${toDate}`
        )
        .set("Authorization", getAuthToken())
        .query()
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getEvents()
  // -----------------------------------------------------------------------
  static getEvents() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/events`)
        .set("Authorization", getAuthToken())
        .query()
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getCategoriesList()
  // -----------------------------------------------------------------------
  static getCategoriesList(eventId) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/event/category?eventId=${eventId}`)
        .set("Authorization", getAuthToken())
        .query()
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getEventBookingList()
  // -----------------------------------------------------------------------
  static getEventBookingList(eventId, categoryId, pageNo) {
    return new Promise((resolve, reject) => {
      request
        .get(
          `${BASE_URL}/event/booking/list?eventId=${eventId}&categoryId=${categoryId}&page=${pageNo}`
        )
        .set("Authorization", getAuthToken())
        .query()
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getEventCategorySummary()
  // -----------------------------------------------------------------------
  static getEventCategorySummary(categoryId) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/event/category/summary?categoryId=${categoryId}`)
        .set("Authorization", getAuthToken())
        .query()
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // updateCategoryStatus()
  // -----------------------------------------------------------------------
  static updateCategoryStatus(body) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/event/category/status`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .send(body)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }
  // -----------------------------------------------------------------------
  // updateCategoryTickets()
  // -----------------------------------------------------------------------
  static updateCategoryTickets(body) {
    console.log({ body });
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/event/category/tickets`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .send(body)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // downloadEventBooking()
  // -----------------------------------------------------------------------
  static downloadEventBooking(categoryId, eventId) {
    return new Promise((resolve, reject) => {
      request
        .get(
          `${BASE_URL}/event/booking/list/download?eventId=${eventId}&categoryId=${categoryId}`
        )
        .set("Authorization", getAuthToken())
        .query()
        .accept(`application/json`)
        .withCredentials()
        .responseType("blob")
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // Booking checkin ()
  // -----------------------------------------------------------------------

  static bookingCheckin(data) {
    console.log({ data });
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/booking/checkin`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(data)
        .end((error, res) => {
          console.log({ res });
          error ? reject(res) : resolve(res);
        });
    });
  }

  // -----------------------------------------------------------------------
  // getSummary()
  // -----------------------------------------------------------------------

  static getSummary() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/membership/package/summary`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  static getChartData() {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/membership/graph/data`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  static cancelMembership(data) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/membership/subscription/cancel`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(data)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  static getUserClubData(data) {
    return new Promise((resolve, reject) => {
      request
        .post(`${BASE_URL}/get/user/clubs`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .send(data)
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }

  static viewNumber(bookingId) {
    return new Promise((resolve, reject) => {
      request
        .get(`${BASE_URL}/view/mobile?booking_id=${bookingId}`)
        .set("Authorization", getAuthToken())
        .set(`Content-Type`, `application/json`)
        .accept(`application/json`)
        .withCredentials()
        .end((error, res) => {
          error ? reject(res) : resolve(res);
        });
    });
  }
}
